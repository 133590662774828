<template>
  <!-- 居中标题 -->
  <div class="newtitle">
    <div style="flex:1"><slot name="header"></slot></div>
    <div class="main" style="flex:1">
      <div class="left"></div>
      <div class="name">
        <font v-if="rules" style="color: #ee1c1c">*</font>{{ name }}
      </div>
      <div class="right"></div>
    </div>
    <div class="footer"><slot name="footer"></slot></div>
  </div>
</template>
<script>
export default {
  name: 'titleCenter',
  props: {
    name: String,
    rules: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.newtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left {
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #ffffff,  #6996FF);
}
.right {
  width: 150px;
  height: 4px;
  background: linear-gradient(90deg, #6996FF,  #ffffff);
}
.name {
  font-size: 16px;
  font-weight: bold;
  color: #38373a;
  padding: 0 10px;
}
.main{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer{
  flex: 1;
  text-align: right;
}
</style>
