  // 扫一扫弹出框
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    :title="title"
    class="scanBox"
  >
    <!-- 开始扫描 -->
    <div class="main" v-if="qrState === '1'">
      <div class="imgBox">
        <img :src="pictureCode" width="140px" height="140px" class="imgStyle" />
        <div v-if="!isHideMask" class="overTimeTip" @click="refresh"><i class="iconfont iconshuaxin"/><p>点击刷新</p></div>
      </div>
      <div class="text01" v-if="isHideMask"><p>使用手机扫一扫</p> <p>即刻完成人脸验证</p></div>
      <div class="text01" v-if="!isHideMask">刷新二维码</div>
    </div>
    <!-- 扫描成功 -->
    <div class="main" v-if="qrState === '2'">
      <img
        :src="require('@/assets/sgin/1@2x.png')"
        width="92px"
        height="147px"
      />
      <div class="text02">
        <p>扫描成功！</p>
        <span>正在进行人脸识别...</span>
      </div>
    </div>
    <!-- 认证成功 -->
    <div class="main" v-if="qrState === '3'">
      <i class="el-icon-success"/>
      <p class="text02">认证成功！</p>
    </div>
    <template slot="footer">
      <base-button  label="返回二维码" @click="backQRcode" v-if="qrState==='2'"></base-button>
      <base-button label="关闭" @click="visibleState = false" type="default" />
    </template>
  </base-dialog>
</template>
<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { qrCodeApi, certifyApi } from '@/api/companyApi'
export default {
  props: {
    visible: Boolean,
    qrCodeData: Object,
    personId: String,
    sealAdminId: String,
    isHideMask: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      title: '扫一扫',
      qrState: '1', // 扫描状态,
      pictureCode: '', // 二维码图片
      // isHideMask: true, // 失效状态
      qrCodeId: '', // 二维码任务id
      clearTime: '', // 二维码状态
      bizTimer: '' // 业务状态
    }
  },
  components: { BaseDialog, BaseButton },
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
        if (!val) {
          clearInterval(this.clearTime)
        }
      }

    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.qrState = '1'
        this.getStutas()
      } else {
        clearInterval(this.clearTime)
        clearInterval(this.bizTimer)
        if (this.qrState === '3') {
          this.$router.push({ path: '/basicDataManagement/certificateManagement' })
        }
      }
    },
    qrCodeData: {
      handler (data) {
        if (data) {
          this.pictureCode = 'data:image/jpeg;base64,' + data.qrCodeBase64
          this.qrCodeId = data.qrCodeId
        }
      },
      deep: true
    }
  },
  mounted () {

  },
  methods: {
    // 在扫描成功,返回二维码按钮
    backQRcode () {
      this.qrState = '1'
      this.refresh()
    },
    // 刷新二维码 重新生成
    refresh () {
      certifyApi.refreshQrCode({ sealAdminId: this.sealAdminId }).then(res => {
        this.isHideMask = true
        this.qrCodeData = res.data
        this.getStutas()
      })
    },
    // 获取二维码状态
    getQrCode () {
      qrCodeApi.getQrCodeStutas({ id: this.qrCodeId }).then(res => {
        // 扫描成功
        if (res.data === '02') {
          this.qrState = '2'
          clearInterval(this.clearTime)
          // 轮询业务状态
          this.bizTimer = setInterval(() => {
            certifyApi.bizStaus({ sealAdminId: this.sealAdminId }).then(res => {
              if (res.data.sealAdminInfoVO.authStatus === '2') {
                this.qrState = '3'
                clearInterval(this.bizTimer)
              }
            })
          }, 1500)
        }
        // 过期
        if (res.data === '03') {
          this.isHideMask = false
          clearInterval(this.clearTime)
        }
      })
    },
    // 轮询 二维码扫描状态
    getStutas () {
      this.clearTime = setInterval(() => {
        this.getQrCode()
      }, 1500)
      if (!this.isHideMask) {
        clearInterval(this.clearTime)
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.clearTime)
    clearInterval(this.bizTimer)
  }

}
</script>
<style lang="scss" src='../index.scss' scoped/>
