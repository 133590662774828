// 个人认证
<template>
  <div class="addAuthentication">
    <backtitle />
    <!-- 提交列表数据 -->
    <div class="mianBox">
      <!-- 企业信息 -->
      <div class="formBox" v-if="enterpriseState">
        <title-center name="企业信息"></title-center>
        <base-form
          :componentList="enterpriseForm"
          :showBtns="false"
          :formAttrs="{
            model: enterpriseQuery,
          }"
          class="formStyle"
          ref="enterpriseData"
        >
        </base-form>
      </div>
      <!-- 签章人信息 -->
      <div class="formBox" v-if="enterpriseState">
        <title-center name="签章人信息">
          <template slot="footer">
            <div style="font-size: 14px">
              无签章人信息？可点击<span class="addBtn" @click="addSigner"
                >新增</span
              >
            </div>
          </template>
        </title-center>
        <!-- 表单 -->
        <base-form
          :componentList="signerFrom"
          :showBtns="false"
          :formAttrs="{
            model: signerQuery,
          }"
          class="formStyle"
          ref="signerData"
        >
        </base-form>
      </div>
      <!--用户协议 -->
      <div class="agereement" v-if="enterpriseState">
        <el-checkbox v-model="checked"> <span style="color:#EE1C1C">*</span>我已同意</el-checkbox>
        <span @click="showAgreement('0')">《CFCA数字证书服务协议》</span>
        <span @click="showAgreement('1')">《安心签平台服务协议》</span>
        <span @click="showAgreement('2')">《安心签平台隐私条款》</span>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div class="footer">
      <!-- 个人提交 -->
      <base-button label="提交认证" v-if="btnState" @click="submitPersonal" />
      <!--重新生成二维码 -->
      <base-button label="重新生成二维码" v-else @click="refresh" />
      <base-button
        label="关闭"
        @click="$router.back()"
        v-if="enterpriseState"
        type='default'
      />
    </div>
    <!-- 用户协议弹出框 -->
    <agreement-dialog
      :visible.sync="agreementVisible"
      :title="title"
      :agreementType="agreementType"
    ></agreement-dialog>
    <!-- 新增签章人弹窗 -->
    <add-signer
      :visible.sync="addSginerVisible"
      :enterpriseId="enterpriseId"
    ></add-signer>
    <!-- 扫描二维码弹窗 -->
    <scan-dialog
      :visible.sync="scanVisible"
      :qrCodeData="qrCodeData"
      :personId="personId"
      :sealAdminId="sealAdminId"
      :isHideMask="isHideMask"
    ></scan-dialog>
  </div>
</template>
<script>
import backtitle from '@/pages/business/components/backtitle.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import TitleCenter from '@/components/packages/title-center/title-center.vue'
import AgreementDialog from './components/agreement-dialog.vue'
import BaseForm from '@/components/common/base-form//base-form'
import AddSigner from './components/add-signer.vue'
import ScanDialog from './components/scan-dialog.vue'
import { certifyApi, supplierApi } from '@/api/companyApi'
import { contactApi } from '@/api/contactApi'
// import Storage from '@/utils/storage'

import {
  signerConfig,
  accountConfig,
  enterpriseConfig
} from './utils/add-authentication-config'
export default {
  components: {
    backtitle,
    BaseButton,
    AgreementDialog,
    TitleCenter,
    BaseForm,
    AddSigner,
    ScanDialog
  },
  data () {
    return {
      attestationType: '', // 认证类型（显示隐藏）
      agreementVisible: false, // 用户协议弹框
      agreementType: '', // 协议类型
      addSginerVisible: false, // 新增签章人弹窗
      scanVisible: false, // 二维码弹窗
      enterpriseState: true, // 企业认证状态（进入打款状态）
      paymentAmount: '', // 打款金额
      checked: false, // 选协议
      submitState: false, // 提交状态
      title: '', // 协议标题
      accountQuery: {}, // 银行信息
      enterpriseQuery: {}, // 企业信息
      signerQuery: {}, // 签章人信息
      enterpriseId: '', // 企业id
      bankList: [], // 银行下拉
      personnelList: [], // 签证人下拉
      qrCodeData: {}, // 二维码数据
      isHideMask: true, // 二维码失效状态
      accountProving: {}, // 打款次数
      personId: '', // 签章人id
      changeState: '', // 变更编辑状态 0新增 BJGR：变更个人。BJGR：编辑个人
      btnState: true, // 提交按钮状态
      sealAdminType: '2', // 认证类型
      sealAdminId: '', // 签章人id
      keyId: '',
      successSubmitDisabled: false // 提交成功,禁用列表
    }
  },
  created () {
    // 获取供应商id
    this.orgAnizationId = this.$store.state.user.userInfo.organizationId
    // 变更编辑状态
    this.changeState = this.$route.query.changeState
    // 获取keyid
    this.keyId = this.$route.query.keyId
  },
  mounted () {
    this.$set(this.signerQuery, 'isDefault', '1')
    this.getGysInfo()
  },
  computed: {
    signerFrom () {
      return signerConfig(this)
    },
    accountFrom () {
      return accountConfig(this)
    },
    enterpriseForm () {
      return enterpriseConfig(this)
    }
  },
  watch: {
    enterpriseId (val) {
      if (val) {
        this.getGysPersonnelList() // 签章人下拉
      }
    },
    changeState (val) {
      if (val === '0') {
        this.signerQuery = {} // 新增状态
      }
      //  else if (val === 'BJGR') { // 编辑个人就直接弹出二维码
      //   this.btnState = false
      //   this.checked = true
      //   this.refresh()
      // }
    },
    keyId (val) {
      if (val) {
        this.getPersonStutas() // 认证状态
      }
    }
  },
  methods: {
    // 页面跳转方法
    jump () {
      this.$router.push({ path: this.tags.path })
    },
    // 显示用户协议弹窗 三种不同协议
    showAgreement (data) {
      const MpaList = ['《CFCA数字证书服务协议》', '《安心签平台服务协议》', '《安心签平台隐私条款》']
      MpaList.forEach((item, index) => {
        if (data === index.toString()) {
          this.title = item
          this.agreementType = index.toString()
          this.agreementVisible = true
        }
      })
    },
    // 获取企业信息
    getGysInfo () {
      if (this.orgAnizationId) {
        supplierApi
          .getEnterpriseInfo({ orgAnizationId: this.orgAnizationId })
          .then((res) => {
            this.enterpriseQuery = { ...res.data, creditName: '社会统一代码' }
            this.enterpriseId = res.data.keyId
          })
      }
    },

    // 签证人下拉
    getGysPersonnelList () {
      contactApi
        .getGysPersonnelList({ enterpriseId: this.enterpriseId })
        .then((res) => {
          if (res.data) {
            this.personnelList = res.data.filter(item => {
              if (this.changeState !== 'BJGR' && this.changeState !== 'BGGR') { // 编辑和变更不过滤下拉项 过滤后无法回显
                if (!item.personSeal) {
                  return item
                }
              } else {
                return item
              }
            })
          }
        })
    },
    // 查询个人状态 获取签章人信息
    getPersonStutas () {
      certifyApi
        .bizStaus({ sealAdminId: this.keyId })
        .then((res) => {
          if (this.changeState !== '0' && res.data.personnelInfoVO) {
            this.$set(this.signerQuery, 'personId', res.data.personnelInfoVO.keyId)
            this.$set(this.signerQuery, 'idCard', res.data.personnelInfoVO.idCard)
            this.$set(this.signerQuery, 'phone', res.data.personnelInfoVO.mobilePhoneNo)
            this.$set(this.signerQuery, 'email', res.data.personnelInfoVO.email)
          }
        })
    },
    // 选择人员后回显
    changeSigner (data) {
      const [obj] = this.personnelList.filter(item => item.keyId === data)
      if (obj) {
        this.btnState = true
        this.$set(this.signerQuery, 'idCard', obj.idCard)
        this.$set(this.signerQuery, 'phone', obj.mobilePhoneNo)
        this.$set(this.signerQuery, 'email', obj.email)
      } else {
        this.signerQuery = {}
      }
    },
    // 提交个人
    submitPersonal () {
      this.$refs.signerData.validate((valid) => {
        valid ? (this.submitState = true) : (this.submitState = false)
      })
      if (!this.checked) {
        this.error('请阅读并勾选用户协议')
      } else if (!this.submitState) {
        this.error('请完整填写认证信息')
      }
      // 提交个人认证，并打开二维码弹框
      if (this.checked & this.submitState) {
        const data = {
          enterpriseId: this.enterpriseId,
          sealAdminType: '2',
          enableStatus: 1,
          ...this.signerQuery
        }
        certifyApi.submitPersonnel(data).then((res) => {
          if (res.data) {
            // this.successSubmitDisabled = true
            this.qrCodeData = res.data
            this.sealAdminId = res.data.sealAdminId
            this.personId = this.signerQuery.personId
            // 修改二维码逻辑
            if (res.data.statusInfo) {
              this.error(res.data.statusInfo)
            } else {
              this.success('提交成功!')
              this.$router.back()
            }
            // if (this.changeState === 'BGGR' || this.changeState === '0') {
            //   this.success('提交成功!')
            //   this.$router.back()
            // } else {
            //   this.scanVisible = true
            //   this.btnState = false
            // }
            // if (this.changeState !== 'BGGR') { // 在变更个人,不会打开弹窗
            //   this.scanVisible = true
            //   this.btnState = false
            // } else {
            //   this.success('提交成功!')
            //   this.$router.back()
            // }
          } else {
            this.submitState = false
            this.error('提交失败，请稍后重试')
          }
        })
      }
    },
    // 重新生成二维码
    refresh () {
      let newSealAdminid = ''
      // this.keyId ? newSealAdminid = this.keyId : newSealAdminid = this.sealAdminId
      if (this.keyId) {
        newSealAdminid = this.keyId
        this.sealAdminId = this.keyId
      } else {
        newSealAdminid = this.sealAdminId
      }
      certifyApi.refreshQrCode({ sealAdminId: newSealAdminid }).then((res) => {
        this.qrCodeData = res.data
        this.scanVisible = true
        this.isHideMask = true
      })
    },
    // 新增签章人
    addSigner () {
      this.addSginerVisible = true
    },
    clearPhoneValidate (data) {
      this.signerQuery.phone = data.replace(/[^\d]/g, '')
    }
  }
}
</script>
<style lang="scss" src='./index.scss' scoped/>
